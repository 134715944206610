/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import PropTypes from 'prop-types';
import PopUpSuccessScheme from '../PopUpScheme/PopUpSuccessScheme';
import { ClearJunctionTable, OpenPaydTable, LPBForm } from './ProviderForms';
import { filterPaymentAccounts, findUserAccount, getPaymentMethodsOptions } from './utils';
import { getAccountTitle } from '../../utils';
import i18nContext from 'components/i18n-context';
import { PAYMENT_PROVIDERS, FORM_TYPES, PAYMENT_METHOD } from 'components/common/constants';
import TransferConfirmationScheme from 'components/common/PopUpScheme/TransferConfirmationScheme';
import { debounce, getErrorMessageForAlert, amountFormattedValue } from 'services/utils';
import { Container } from 'uikit/Container/Container';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import Alert from 'uikit/Alert/Alert';
import { PopUp } from 'uikit/PopUp/PopUp';
import './PaymentForm.scss';
import { ROUTE_PATHS } from '../../../routes/constants';

const SIMPLE_FORM = FORM_TYPES.SIMPLE;
const ADVANCED_FORM = FORM_TYPES.ADVANCED;

const PaymentForm = ({ customerNumber, userStore, paymentStore, betweenAccountsStore, currencyExchangeStore }) => {
  const i18n = useContext(i18nContext);
  const navigate = useNavigate();
  const providerType = paymentStore.currentAccount?.transfer_provider;
  const [isFormResetAfterAccountChanging, setIsFormResetAfterAccountChanging] = useState(true);

  useEffect(() => {
    if (paymentStore.isSuccess) {
      userStore.getUserAccounts();
    }
  }, [paymentStore.isSuccess]);

  const activeTab = [PAYMENT_METHOD.SWIFT, PAYMENT_METHOD.TARGET2].includes(paymentStore.paymentMethod)
    ? ADVANCED_FORM
    : SIMPLE_FORM;

  // Preselecting account with Clear Junction provider
  useEffect(() => {
    const paymentAccounts = filterPaymentAccounts(userStore.userAccounts);
    const hasAccounts = paymentAccounts.length > 0;
    const clearJunctionAccount = paymentAccounts.find(
      ({ transfer_provider }) => transfer_provider === PAYMENT_PROVIDERS.CLEARJUNCTION
    );
    clearJunctionAccount && !paymentStore.currentAccount && paymentStore.setSelectedAccount(clearJunctionAccount);
    if (paymentStore.currentAccount) {
      handleAccountChange('', paymentStore.currentAccount.wallet_number);
    }
    if (!paymentStore.currentAccount && !clearJunctionAccount && hasAccounts) {
      handleAccountChange('', accountsOptions[0].key);
    }
  }, [userStore.userAccounts]);

  const accountsOptions = filterPaymentAccounts(userStore.userAccounts)?.map((account) => {
    const title = getAccountTitle(account);

    return {
      key: account?.wallet_number,
      value: title,
      currency: account?.currency
    };
  });

  const setProviderData = (data) => paymentStore.setProviderData(data);
  const calculateCommission = (customerNumber, data) => paymentStore.calculatePaymentCommission(customerNumber, data);
  const checkTransfer = (customerNumber, data) => paymentStore.checkTransfer(customerNumber, data);
  const validateTransfer = (...args) => paymentStore.validateTransfer(...args);
  const removePaymentFile = (fileId) => paymentStore.removePaymentFile(fileId);
  // eslint-disable-next-line max-len
  const validateIban = async (iban) => {
    const foundedUsedAccount = findUserAccount(userStore.userAccounts, iban, paymentStore?.currentAccount?.currency);

    if (
      foundedUsedAccount &&
      foundedUsedAccount.currency === paymentStore.currentAccount.currency &&
      foundedUsedAccount.wallet_number !== paymentStore.currentAccount.wallet_number
    ) {
      betweenAccountsStore.resetStore();
      betweenAccountsStore.setFromToAccounts(paymentStore.currentAccount, foundedUsedAccount);
      betweenAccountsStore.setIsRedirectFromPaymentFormStatus(true);
      navigate(ROUTE_PATHS.BETWEEN_ACCOUNTS);
    } else if (foundedUsedAccount && foundedUsedAccount.currency !== paymentStore.currentAccount.currency) {
      currencyExchangeStore.resetExchangeStore();
      currencyExchangeStore.setFromToAccounts(paymentStore.currentAccount, foundedUsedAccount);
      currencyExchangeStore.setIsRepeatTransactionStatus(true);
      navigate(ROUTE_PATHS.CURRENCY_EXCHANGE);
    } else {
      await paymentStore.validateIban(iban, providerType, paymentStore.currentAccount?.wallet_number);
    }
  };
  const clearIbanCredentials = () => paymentStore.clearIbanCredentials();
  const setError = (error) => paymentStore.setError(error);
  const setIban = (iban) => paymentStore.setIban(iban);
  const setIsNonIban = (status) => paymentStore.setIsNonIban(status);
  const resetPaymentMethods = () => paymentStore.resetPaymentMethodInfo();
  const verifyPayment = (securityCode) => paymentStore.verifyPaymentSecurityCode(customerNumber, securityCode);
  const resendVerifyCode = () => paymentStore.resendPaymentSecurityCode();

  const validationProps = {
    i18n,
    customerNumber,
    onChange: setProviderData,
    onSubmit: validateTransfer,
    previousTransaction: paymentStore.previousTransactionInfo,
    currency: paymentStore?.currentAccount?.currency,
    paymentMethod: paymentStore?.paymentMethod,
    providerType
  };
  const formProps = {
    account: { ...paymentStore.currentAccount },
    commission: paymentStore.commission,
    isCommissionLoading: paymentStore.isCommissionLoading,
    paymentMethod: paymentStore.paymentMethod,
    resetPaymentMethods,
    uploadedFiles: paymentStore.uploadedFiles,
    removePaymentFile: removePaymentFile,
    customerNumber: customerNumber,
    calculateCommission,
    checkTransfer: debounce(checkTransfer, 400),
    isRepeatPayment: paymentStore.isRepeatPayment,
    iban: paymentStore.iban,
    setIban,
    setIsNonIban,
    isInternalIban: paymentStore.isInternalIban,
    checkIban: validateIban,
    isIbanCheckLoading: paymentStore.isIbanCheckLoading,
    uploadDocuments: paymentStore.uploadDocuments(customerNumber),
    error: paymentStore.error,
    setError,
    isSuccess: paymentStore.isSuccess,
    isLoading: paymentStore.isLoading || paymentStore.isFileUploading,
    currency: paymentStore?.currentAccount?.currency,
    formType: activeTab,
    ibanCredentials: paymentStore?.ibanCredentials,
    clearIbanCredentials,
    validationProps,
    countries: paymentStore?.currentAccount?.countries,
    providerType,
    isFormResetAfterAccountChanging,
    setIsFormResetAfterAccountChanging
  };

  const getProviderTable = (providerType) => {
    switch (providerType) {
      case PAYMENT_PROVIDERS.CLEARJUNCTION: {
        return <ClearJunctionTable {...formProps} />;
      }
      case PAYMENT_PROVIDERS.OPENPAYD: {
        return <OpenPaydTable {...formProps} />;
      }
      case PAYMENT_PROVIDERS.LPB: {
        return <LPBForm {...formProps} />;
      }
      case PAYMENT_PROVIDERS.MANUAL: {
        return <OpenPaydTable {...formProps} />; // same as OP
      }
      case PAYMENT_PROVIDERS.RYVYL: {
        return <OpenPaydTable {...formProps} />; // same as OP and Manual
      }
      default:
        return <div>{i18n.getMessage('transfer.provider.notFound')}</div>;
    }
  };

  const handleAccountChange = (name, data) => {
    const selectedAccount = filterPaymentAccounts(userStore.userAccounts).find(
      ({ wallet_number }) => wallet_number === data
    );

    if (
      paymentStore.currentAccount?.wallet_number !== selectedAccount?.wallet_number ||
      (!paymentStore.error && paymentStore?.availablePaymentMethods?.length === 0)
    ) {
      paymentStore.setAccountInfo(selectedAccount);
      setIsFormResetAfterAccountChanging(false);
    }
  };

  const handlePaymentMethodChange = (name, data) => {
    paymentStore.setPaymentMethod(data);
  };

  const getAvailableBalance = () =>
    paymentStore?.currentAccount &&
    i18n.getMessage('sendMoney.topLabel', {
      available: amountFormattedValue(paymentStore?.currentAccount.available),
      currency: paymentStore?.currentAccount.currency
    });

  useEffect(() => {
    if (!paymentStore.isRepeatPayment) {
      paymentStore.resetPaymentMethodInfo();
    }
  }, [paymentStore.isNonIban]);

  return (
    <Container className={'send-money'} header={i18n.getMessage('container.sendMoney')}>
      <Alert
        className={'send-money-alert'}
        type={'warning'}
        message={
          paymentStore.error && !paymentStore.error?.type
            ? getErrorMessageForAlert(i18n, paymentStore.error, true, paymentStore?.paymentMethod)
            : ''
        }
      />
      <div className={'send-money-wrapper'}>
        <div className={'inputs-wrapper'}>
          <InputDropDown
            isRequired={true}
            topLabel={getAvailableBalance()}
            label={i18n.getMessage('transfer.form.accountNumber.label')}
            value={paymentStore.currentAccount?.wallet_number}
            currency={paymentStore.currentAccount?.currency}
            onChange={handleAccountChange}
            options={accountsOptions}
            isMulti={false}
          />
          <InputDropDown
            isRequired={true}
            label={i18n.getMessage('transfer.form.paymentMethod.label')}
            value={paymentStore?.paymentMethod}
            onChange={handlePaymentMethodChange}
            options={getPaymentMethodsOptions(paymentStore?.availablePaymentMethods)}
            isMulti={false}
          />
        </div>
        {getProviderTable(providerType)}
      </div>
      <PopUp
        className={paymentStore.isSuccess ? 'transaction-success' : 'transaction-info'}
        show={paymentStore.isTransactionConfirmation || paymentStore.isSuccess}
        alignOnCenter={paymentStore.isSuccess}
        onClose={
          paymentStore.isSuccess
            ? () => paymentStore.resetSuccess()
            : () => paymentStore.setIsTransactionConfirmation(false)
        }
      >
        {paymentStore.isSuccess ? (
          <PopUpSuccessScheme
            onClose={() => paymentStore.resetSuccess()}
            message={i18n.getMessage('popUp.message.moneyTransfer')}
          />
        ) : (
          <TransferConfirmationScheme
            isLoading={paymentStore.isLoading}
            transferData={{
              ...paymentStore.serverTransactionData,
              uploadedFiles: paymentStore.uploadedFiles
            }}
            error={paymentStore.confirmationPopupError}
            generateSecurityCode={paymentStore.createTransfer(customerNumber)}
            resendSecurityCode={resendVerifyCode}
            clearError={() => paymentStore.clearConfirmationPopupError()}
            onConfirm={verifyPayment}
            onClose={() => paymentStore.setIsTransactionConfirmation(false)}
          />
        )}
      </PopUp>
    </Container>
  );
};

PaymentForm.propTypes = {
  customerNumber: PropTypes.string,
  userStore: MobXPropTypes.observableObject,
  paymentStore: MobXPropTypes.observableObject,
  betweenAccountsStore: MobXPropTypes.observableObject,
  currencyExchangeStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  customerNumber: stores.userStore.userData.account?.account_number,
  userStore: stores.userStore,
  paymentStore: stores.paymentStore,
  betweenAccountsStore: stores.betweenAccountsStore,
  currencyExchangeStore: stores.currencyExchangeStore
}))(observer(PaymentForm));
